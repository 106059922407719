import { useStaticQuery, graphql } from "gatsby";

export const useData = () => {
  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  `);

  const result: [
    {
      id: string;
      to: string;
      title: string;
    }
  ] = data.allServicesJson.edges.map((item) => ({
    id: item.node.id,
    to: "/services/" + item.node.id,
    title: item.node.title,
  }));

  return result;
};
