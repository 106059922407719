import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SubLink, StyledLink, StyledSubMenu } from "./submenuStyles";

const SubMenu = ({ lists, showSubMenu }) => {
  if (!lists) return null;

  const items = lists.map((list, index) => {
    const { title, to, id } = list;

    return (
      <SubLink key={title} hiddenDesktop={id == 100}>
        <StyledLink
          to={to}
          title={title}
          onMouseOut={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {title}
        </StyledLink>
      </SubLink>
    );
  });

  return <StyledSubMenu show={showSubMenu}>{items}</StyledSubMenu>;
};

export default SubMenu;
