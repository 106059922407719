import React from "react";
import { Link } from "gatsby";
import styled, { keyframes } from "styled-components";
import { colors, breakpoints } from "../../styles/variables";

export const SubLink = styled.li`
  text-decoration: none;
  padding-right: 0;
  color: ${colors.secondary};
  display: block !important;
  @media (min-width: ${breakpoints.xl}) {
    ${(props: { hiddenDesktop: boolean }) =>
      props.hiddenDesktop
        ? `display:none !important;`
        : `display:block !important;`}

    margin-left: -2rem;
    background-color: white;
    max-width: 18rem;
    padding-left: 1.5rem;
    padding-right: 3rem;
    &:first-child {
      padding-top: 1.5rem;
    }
    &:last-child {
      padding-bottom: 0.5rem;
    }
  }
`;

export const StyledLink = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: #aeabab;
  font-size: 0.8rem;
  display: inline-block;
  width: 100%;
  margin-bottom: 0.8rem;
  ${(props: { hiddenDesktop: boolean }) =>
    props.hiddenDesktop ? `display:none;` : `display:inline-block;`}
  &:hover {
    color: ${colors.dentCyan};
  }

  @media (min-width: ${breakpoints.xl}) {
    color: #000;
    font-size: 1rem;
  }
`;

const slideAnimationY = keyframes`
    from {
        opacity: 0; transform: translateY(-1rem);   
    }
    to {
        opacity: 1; transform: translateY(0); 
    }
`;

export const StyledSubMenu = styled.ul`
  padding-left: 0.375rem;
  min-width: 4rem;
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  margin-top: 0.5rem;
  width: 100%;
  height: 100%;

  @media (min-width: ${breakpoints.xl}) {
    position: absolute;
    padding-top: 2rem;
    animation: ${slideAnimationY} 0.3s ease-in-out;

    ${(props: { show: boolean }) =>
      props.show ? ` display:block;` : ` display:none;`}
  }
`;
