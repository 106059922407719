import { FaFacebookF, FaLinkedin, FaTwitter, FaInstagram } from "react-icons/fa";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors } from "../../styles/variables";

export const FooterContainer = styled.div`
  background: ${colors.secondary};
  color: #fff;
  margin-top: 5rem;
`;
export const FooterBox = styled.div`
  max-width: ${breakpoints.xl};
  margin: 0 auto;
  padding: 4rem 0 2rem 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }
`;
export const ColLeft = styled.div`
  padding-top: 1rem;
  margin: 0 auto 3rem auto;
  @media (min-width: ${breakpoints.md}) {
    padding-top: 2rem;
    flex: 1;
  }
`;
export const ColCenter = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  @media (min-width: ${breakpoints.md}) {
    padding-top: 2rem;
    flex: 1;
  }
`;

export const ColRight = styled.div`
  padding-top: 1rem;
  @media (min-width: ${breakpoints.md}) {
    padding-top: 2rem;
    margin-left: 0;
    flex: 1;
  }
`;
export const Copyright = styled.div`
  padding-top: 2rem;
  width: 100%;
  font-family: "Raleway", sans-serif;
  font-size: 0.625rem;
  font-weight: 300;
  text-align: center;
  @media (min-width: ${breakpoints.sm}) {
    font-size: 0.75rem;
  }
`;
export const SocialRow = styled.div`
  display: flex;
  width: 12.5rem;
  justify-content: center;
  margin-top: -1rem;
  @media (min-width: ${breakpoints.md}) {
    margin: 0rem 0.5rem 1rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    justify-content: flex-start;
    margin-left: 0.8rem;
  }
`;
export const LogoImgContainer = styled.div`
  display: block;
  width: 12.5rem;
  margin: -1rem auto 0 0;
`;
const IconStyle = css`
  font-size: 1.25rem;
  margin: 0.5rem 0.75rem;
  color: #fff;
`;
export const FacebookIcon = styled(FaFacebookF)`
  ${IconStyle}
`;
export const LinkedinIcon = styled(FaLinkedin)`
  ${IconStyle}
`;
export const TwitterIcon = styled(FaTwitter)`
  ${IconStyle}
`;
export const InnstaIcon = styled(FaInstagram)`
  ${IconStyle}
`;
export const StyledIconAnchor = styled.a`
  font-size: 1.25rem;
  color: ${colors.dark};
  &:hover {
    color: ${colors.primary};
  }
`;
export const StyledLink = styled(Link)`
  font-size: 1rem;
  color: #fff;
  line-height: 2rem;
  text-decoration: none;
  text-align: center;
  &:hover {
    color: ${colors.primary};
  }
  @media (min-width: ${breakpoints.sm}) {
    text-align: left;
  }
`;
export const StyledAnchor = styled.a`
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  &:hover {
    color: ${colors.primary};
  }
`;
export const InfoContainer = styled.div`
  margin: 2rem auto;
  @media (min-width: ${breakpoints.md}) {
    margin: 3rem 0 auto auto;
  }
`;
export const InfoBox = styled.div`
  text-align: center;
  margin: 0rem auto;
  @media (min-width: ${breakpoints.md}) {
    text-align: left;
  }
`;
export const InfoText = styled.span`
  font-weight: 300;
  font-size: 1rem;
  line-height: 2rem;
  @media (min-width: ${breakpoints.sm}) {
    margin-left: 1rem;
  }
`;

export const SiteGroup = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 2rem;
  }
`;
export const SiteGroupTitle = styled.p`
  font-weight: 800;
  color: #fff;
  font-size: 1.3rem;
  text-align: center;
  @media (min-width: ${breakpoints.sm}) {
    text-align: left;
  }
`;
export const AddressText = styled.span`
  display: block;
  font-weight: 300;
  font-size: 1rem;
  line-height: 2rem;
  @media (min-width: ${breakpoints.sm}) {
    margin-left: 1rem;
  }
`;
