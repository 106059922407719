export const menus = [
  {
    title: "About Us",
    id: 0,
    to: "/about",
    submenus: [
      { to: "/about", title: "Our Practice", id: "our_prosthodontics" },
      {
        to: "/prosthodontist",
        title: "Our Principal Prosthodontist",
        id: "our_principal_prosthodontist",
      },
    ],
  },
  {
    title: "Our Services",
    id: 1,
    to: "/services",
    submenus: [],
  },
  {
    title: "Start The Process",
    id: 2,
    to: "/new-patient",
  },
  {
    title: "Referral Form",
    id: 3,
    to: "/referral",
  },
  {
    title: "Contact Us",
    id: 4,
    to: "/contact",
  },
];
