import React, { useState, useEffect } from "react";
import SubMenu from "./SubMenuItem";
import LogoImg from "./LogoImage";
import { menus } from "./menulist";
import * as services from "../../data/services.json";
import { useData } from "./useData";
import {
  HeaderTop,
  Container,
  LogoContainer,
  Logo,
  NavigationContainer,
  Navigation,
  Menu,
  StyledLink,
  SubMenuContainer,
  MenuButtonContainer,
  Hamberger,
  HambergerButton,
  StyledSubMenuContainer,
  CanvasOverlay,
  ExpandIcon,
  LinkWrapper,
} from "./styles";

const Header = () => {
  const data = useData();
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenuBackDropDesktop, setShowSubMenuBackDropDesktop] = useState(
    false
  );
  const [showSubMenuId, setShowSubMenuId] = useState(null);
  const [showSubMenuDesktop, setShowSubMenuDesktop] = useState(false);
  const [showSubMenuBackdropMobile, setshowSubMenuBackdropMobile] = useState(
    true
  );
  const [mobile, setMobile] = useState(false);
  const [activeSubMenuMobile, setActiveSubMenuMobile] = useState();

  useEffect(() => {
    menus.forEach(addSubmenus);
  }, []);

  function addSubmenus(item, index, arr) {
    if (item.id == 1) {
      item.submenus = [];
      item.submenus.push({ to: "/services", title: "Service List", id: 100 });
      data.forEach((element, i) => {
        item.submenus.push({
          to: "/services/" + element.id,
          title: element.title,
          id: i,
        });
      });
    }
    arr[index] = item;
  }

  const onMouseEnter = (e) => {
    const id = e.target.id;
    setShowSubMenuId(id);
    if (!showSubMenuBackDropDesktop) {
      setShowSubMenuBackDropDesktop(true);
    }
    if (!showSubMenuDesktop) {
      setShowSubMenuDesktop(true);
    }
  };
  const onMouseLeave = (e) => {
    setShowSubMenuId(null);
    if (showSubMenuBackDropDesktop) {
      setShowSubMenuBackDropDesktop(false);
    }
    if (showSubMenuDesktop) {
      setShowSubMenuDesktop(false);
    }
  };

  const onClickMenuButton = () => {
    setMobile(true);
    showMenu ? setShowMenu(false) : setShowMenu(true);
    showSubMenuBackdropMobile
      ? setshowSubMenuBackdropMobile(false)
      : setshowSubMenuBackdropMobile(true);
  };

  const onClickOverlay = () => {
    setShowMenu(false);
    setshowSubMenuBackdropMobile(false);
  };

  const handleMenuClick = (e) => {
    const id = e.target.id;

    if (mobile) {
      if (id < 2) e.preventDefault();
      setActiveSubMenuMobile(id);
    }
  };

  return (
    <>
      <HeaderTop>
        <Container>
          <LogoContainer to="/">
            <Logo>
              <LogoImg />
            </Logo>
          </LogoContainer>
          <NavigationContainer show={showMenu}>
            <Navigation>
              {menus.map((list, index) => {
                return index < 2 ? (
                  <Menu
                    key={list.id}
                    id={list.id}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                  >
                    <StyledLink
                      title={list.title}
                      id={list.id}
                      to={list.to}
                      onClick={handleMenuClick}
                    >
                      {list.title}
                    </StyledLink>

                    <SubMenuContainer
                      show={list.id.toString() === activeSubMenuMobile}
                    >
                      <SubMenu
                        lists={list.submenus}
                        showSubMenu={showSubMenuId === list.id.toString()}
                      />
                    </SubMenuContainer>
                  </Menu>
                ) : (
                  <Menu key={list.id}>
                    <StyledLink
                      title={list.title}
                      id={list.id}
                      to={list.to}
                      onClick={handleMenuClick}
                    >
                      {list.title}
                    </StyledLink>

                    <SubMenuContainer
                      show={list.id.toString() === activeSubMenuMobile}
                    >
                      <SubMenu
                        lists={list.submenus}
                        showSubMenu={showSubMenuDesktop}
                      />
                    </SubMenuContainer>
                  </Menu>
                );
              })}
            </Navigation>
          </NavigationContainer>
          <MenuButtonContainer show={showMenu}>
            <HambergerButton
              type="checkbox"
              onChange={onClickMenuButton}
              checked={showMenu}
            />
            <Hamberger show={showMenu} />
            <Hamberger show={showMenu} />
            <Hamberger show={showMenu} />
          </MenuButtonContainer>
        </Container>
      </HeaderTop>
      <CanvasOverlay show={showMenu} onClick={onClickOverlay} />
    </>
  );
};

export default Header;
