export const colors = {
  primary: "#0bb4ac",
  light: "#F7FAFC",
  secondary: "#002345",
  lightGray: "#EDF2F7",
  gray: "#efeaea",
  lightDark: "#686666",
  darkGray: "#a3a3a3",
  lightBlue: "#293f5e",
  dark: "#333",
  dentBlue: "#158da7",
  dentCyan: "#0bb4ac",
  dentGray: "#828282",
};

export const breakpoints = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};
