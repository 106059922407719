import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const KccauLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      kccauLogoImg: file(relativePath: { eq: "GPlogogrey.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return <Img fixed={data.kccauLogoImg.childImageSharp.fixed} fadeIn={false} />;
};

export default KccauLogo;
