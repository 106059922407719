import React from "react";
import {
  FooterContainer,
  FooterBox,
  Row,
  ColLeft,
  ColCenter,
  ColRight,
  Copyright,
  SocialRow,
  LogoImgContainer,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  InnstaIcon,
  StyledIconAnchor,
  StyledLink,
  StyledAnchor,
  InfoContainer,
  InfoBox,
  InfoText,
  SiteGroup,
  SiteGroupTitle,
  AddressText,
} from "./styles";
import KccauLogo from "./Images";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterBox>
        <Row>
          <ColLeft>
            <StyledLink to="/">
              <LogoImgContainer>
                <KccauLogo />
              </LogoImgContainer>
            </StyledLink>

            <SocialRow>
              <StyledIconAnchor
                href="https://www.facebook.com/sharer.php?u=http%3A%2F%2Fgippslandprosthodontics.com.au%2Findex.php"
                target="_blank"
              >
                <FacebookIcon />
              </StyledIconAnchor>
              <StyledIconAnchor
                href="http://twitter.com/share?url=http://gippslandprosthodontics.com.au/index.php&text=Coming%20Soon"
                target="_blank"
              >
                <TwitterIcon />
              </StyledIconAnchor>
              <StyledIconAnchor
                href="http://www.linkedin.com/shareArticle?mini=true&url=http://gippslandprosthodontics.com.au/index.php"
                target="_blank"
              >
                <LinkedinIcon />
              </StyledIconAnchor>
              <StyledIconAnchor
                href="https://www.instagram.com/gippsland_prosthodontics/?hl=en"
                target="_blank"
              >
                <InnstaIcon />
              </StyledIconAnchor>
            </SocialRow>
          </ColLeft>
          <ColCenter>
            <SiteGroup>
              <SiteGroupTitle>Support</SiteGroupTitle>

              <StyledLink to="/">Home</StyledLink>

              <StyledLink to="/about">About Us</StyledLink>

              <StyledLink to="/contact">Contact Us</StyledLink>
            </SiteGroup>
          </ColCenter>
          <ColCenter>
            <SiteGroup>
              <SiteGroupTitle>Services</SiteGroupTitle>
              <StyledLink to="/services">Our Services</StyledLink>
              <StyledLink to="/new-patient">Start The Process</StyledLink>
              <StyledLink to="/referral">Referral Form</StyledLink>
            </SiteGroup>
          </ColCenter>

          <ColRight>
            <InfoContainer>
              <InfoBox>
                <StyledAnchor href="tel:03 5642 6947">
                  <InfoText>03 5642 6947</InfoText>
                </StyledAnchor>
              </InfoBox>
              <InfoBox>
                <StyledAnchor href="mailto:reception@gippslandprosthodontics.com.au">
                  <InfoText>reception@gippslandprosthodontics.com.au</InfoText>
                </StyledAnchor>
              </InfoBox>
              <InfoBox>
                <StyledAnchor
                  href="https://www.google.com/maps/place/Gippsland+Prosthodontics/@-38.1618264,145.939842,17z/data=!3m1!4b1!4m5!3m4!1s0x6b29970f09a6e991:0xe37828d3aeacb131!8m2!3d-38.1618264!4d145.9420307"
                  target="_blank"
                >
                  <AddressText>132 Albert Rd, Warragul VIC 3820</AddressText>
                  {/* <AddressText>Warragul VIC 3820</AddressText> */}
                </StyledAnchor>
              </InfoBox>
            </InfoContainer>
          </ColRight>
        </Row>
        <Copyright>
          Copyright &copy; {new Date().getFullYear()} Gippsland Prosthodontics.
          All rights reserved.
        </Copyright>
      </FooterBox>
    </FooterContainer>
  );
};

export default Footer;
